import React from 'react';
import {
    HeadingWrapCont,
    HeadingElements,
    Line,
    SocialCont,
    Social,
} from './styles';
import { Container } from '../container';
import { HeadingMain } from '../../styles/';

function HeadingWrap() {
    return (
        <Container>
            <HeadingWrapCont>
                <HeadingElements>
                    <Line />
                    <SocialCont>


                        <a
                            title='Github Repository'
                            target='_blank'
                            rel='noreferrer'
                            href='https://github.com/realbaz'
                        >
                            <Social>
                                <svg
                                    className='git'
                                    width='40'
                                    height='40'
                                    viewBox='0 0 800 800'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                      <path d="M400 139c144 0 260 116 260 260 0 115-75 213-178 247-9 3-17-2-17-13v-71c0-35-18-48-18-48 57-6 119-28 119-128 0-44-27-70-27-70s14-29-2-69c0 0-22-7-72 27-42-12-88-12-130 0-50-34-72-27-72-27-16 40-2 69-2 69s-27 26-27 70c0 100 62 122 119 128 0 0-14 10-17 35-15 7-53 18-76-22 0 0-13-25-39-27 0 0-26 0-2 16 0 0 17 8 29 38 0 0 16 51 88 35v44c0 11-9 16-18 13-103-34-178-132-178-247 0-144 116-260 260-260z"
                                      fill='#9a9a9a'/>

                                </svg>
                            </Social>
                        </a>
                        <a
                            title='Linkedin'
                            target='_blank'
                            rel='noreferrer'
                            href='https://www.linkedin.com/in/bharathwajrajan/'
                        >

                            <Social>
                                <svg
                                    className='insta'
                                    width='40'
                                    height='40'
                                    viewBox='0 0 800 800'
                                    fill='none'
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                 <path d="M268 629h-97V319h97zm157 0h-97V319h93v42h1q31-50 93-50 114 0 114 133v185h-96V466q0-70-49-70-59 0-59 69z"
                                 fill='#9a9a9a'/>
                                 <circle cx="219" cy="220" r="56" fill='#9a9a9a'/>
                                </svg>
                            </Social>
                        </a>
                        <a
                            title='Twitter'
                            target='_blank'
                            rel='noreferrer'
                            href='https://twitter.com/getbazzed'
                        >
                            <Social>
                                <svg
                                    className='twitter'
                                    width='40'
                                    height='40'
                                    viewBox='0 0 800 800'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >

                                    <path d="M679 239s-21 34-55 57c7 156-107 329-314 329-103 0-169-50-169-50s81 17 163-45c-83-5-103-77-103-77s23 6 50-2c-93-23-89-110-89-110s23 14 50 14c-84-65-34-148-34-148s76 107 228 116c-22-121 117-177 188-101 37-6 71-27 71-27s-12 41-49 61c30-2 63-17 63-17z"
                                    fill='#9a9a9a'/>

                                    />
                                </svg>
                            </Social>
                        </a>
                    </SocialCont>
                </HeadingElements>
                <HeadingMain>
                    <span>
                        Hello, I'm Baz. <br />
                    </span>
                    <span>
                        Mathematician, <span className='blue'>Developer</span>,{' '}
                        <br />
                    </span>
                    <span>Engineer, and an avid learner.</span>
                </HeadingMain>
            </HeadingWrapCont>
        </Container>
    );
}

export default HeadingWrap;
