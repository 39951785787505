import styled, { keyframes } from 'styled-components';
import { breakpoints } from '../../components/Media';
import quotation from '../../images/quotation.png';

export const HeadingWrapCont = styled.div`
    padding: 20px;
    background-image: url(${quotation});
    background-position: 0 0;
    background-repeat: no-repeat;
`;

export const HeadingElements = styled.div`
    display: flex;
    align-items: center;
`;

export const drawLine = keyframes`
    from {
        width: 0px;
        opacity: 0;
    } 

    to{
        width: 200px;
        opacity: 1;
    }
`;

export const Line = styled.div`
    height: 2px;
    background-color: gray;
    width: 0px;
    animation: ${drawLine} 1s forwards;

    @media (min-width: ${breakpoints.mobileMax}) {
        width: 0;
    }
`;

export const SocialCont = styled.div`
    width: auto;
    height: auto;
    display: flex;
    margin-left: 20px;
`;

export const Social = styled.div`
    width: 35px;
    height: 35px;
    margin: 0 15px;
    background-repeat: no-repeat;
    background-size: 35px 35px;
    background-position: center center;
    transition-duration: 0.2s;
    opacity: 1;

    .insta,
    .twitter,
    .git {
        path {
            transition-duration: 0.5s;
        }
    }

    :hover {
        .insta {
            path {
                fill: #0072b1;
            }
            circle{
                fill: #0072b1;
            }
        }
    }

    :hover {
        .twitter {
            path {
                fill: #00acee;
            }
        }
    }

    :hover {
        .git {
            path {
                fill: black;
            }
        }
    }
`;
